import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../common/Link"

export default function InforBesokMenu({ lang }) {
  const data = useStaticQuery(graphql`
    query {
      sv: wpMenu(slug: { eq: "information" }) {
        id
        menuItems {
          nodes {
            uri
            label
          }
        }
      }
      en: wpMenu(slug: { eq: "information-engelska" }) {
        id
        menuItems {
          nodes {
            uri
            label
          }
        }
      }
    }
  `)

  const menuSE = data.sv.menuItems.nodes
  const menuEN = data.en.menuItems.nodes

  var menu = []
  lang === "en" ? (menu = menuEN) : (menu = menuSE)

  console.log(menuSE, menuEN)

  return (
    <div className="bg-black">
      <div className="bg-primary text-white  p-4 lg:p-8 flex flex-col lg:flex-row w-full justify-center gap-4 lg:gap-8">
        {menu.map((menuItem, i) => {
          return <MenuItem key={i} menuItem={menuItem} />
        })}
      </div>
    </div>
  )
}

function MenuItem({ menuItem }) {
  const { uri, label } = menuItem
  return (
    <Link
      to={uri}
      className="font-bold hover:text-gray-900"
      activeClassName="text-gray-900"
    >
      {label}
    </Link>
  )
}
