import React from "react"
import HeaderFeaturedImage from "../common/HeaderFeaturedImage"
import InforBesokMenu from "../info/InforBesokMenu"

export default function OmOssComponent({ omOss, featuredImage, title, lang }) {
  const {
    huvudrubrikOmoss,
    primartTextinnehallOmoss,
    snabbFaktaOmoss,
    snabbFaktaRubrikOmoss,
    underrubrikOmoss,
  } = omOss
  return (
    <div>
      {featuredImage && (
        <HeaderFeaturedImage
          featuredImage={featuredImage}
          title={title}
          // subTitle={rubrikSidhuvudRestaurang}
        />
      )}
      <InforBesokMenu lang={lang} />
      <div className="max-w-4xl mx-auto px-4 lg:px-8 py-12 flex flex-col gap-4 lg:gap-8 relative">
        <div className="text-center">
          <h2 className="text-3xl lg:text-5xl">{huvudrubrikOmoss}</h2>
          <h3 className="text-xl lg:text-2xl ">
            {" "}
            <div
              dangerouslySetInnerHTML={{ __html: underrubrikOmoss }}
              className="wp-content"
            />
          </h3>
        </div>

        <div className="flex  flex-col md:flex-row gap-8">
          <div className="flex flex-col gap-4 lg:gap-8">
            <div
              dangerouslySetInnerHTML={{ __html: primartTextinnehallOmoss }}
              className="wp-content"
            />
            {snabbFaktaOmoss && (
              <div className="flex flex-col  bg-thirteenth/20  p-4 lg:p-8">
                <div>
                  <h4 className="">{snabbFaktaRubrikOmoss}</h4>
                </div>
                <div>
                  <div
                    dangerouslySetInnerHTML={{ __html: snabbFaktaOmoss }}
                    className="wp-content"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
