import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function HeaderFeaturedImage({
  featuredImage,
  title,
  subTitle,
}) {
  const img = getImage(featuredImage?.node.localFile.childImageSharp)

  return (
    <div className="relative bg-black">
      <div className="h-[50vh] lg:h-[70vh] relative overflow-hidden">
        <GatsbyImage
          image={getImage(img)}
          className="w-full h-full object-cover opacity-50"
        />
      </div>
      <div className="absolute top-0  w-full h-full flex items-center justify-center  text-white ">
        <div className="max-w-screen-2xl mx-auto  text-center px-4 lg:px-8 ">
          <div className="flex flex-col gap-4 lg:gap-8">
            <h1 className=" w-full realative z-30 tracking-normal">{title}</h1>
            {subTitle && (
              <div className="text-xl lg:text-3xl font-semibold max-w-3xl">
                <p>{subTitle}</p>
              </div>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  )
}
